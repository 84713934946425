
































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ThreeStateButtonGroup extends Vue {
  @Prop() public label!: string

  /** NB! 3 состояния: null | true | false */
  @Prop(Boolean) public readonly value!: null | boolean


  public get type() {
    return {
      true: this.value === true ? 'is-primary' : '',
      false: this.value === false ? 'is-primary' : '',
      nothing: this.value === null ? 'is-primary' : '',
    }
  }
}
