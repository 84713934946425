












































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

import ThreeStateButtonGroup from '@/components/Controls/ThreeStateButtonGroup.vue'
import { filterFields } from './constants'


interface Filters extends Record<string, undefined | null | string | number | boolean> {
  filter_category_id: undefined | string | number
  filter_moderated: null | boolean
  filter_patent: null | boolean
}

const emptyFilters = {
  filter_category_id: undefined,
  filter_moderated: null,
  filter_patent: null,
}

@Component({
  components: {
    ThreeStateButtonGroup,
  },
})
export default class ModalFilter extends Vue {
  @Prop(Boolean) public readonly show: boolean | undefined

  public display: boolean = false

  public filters: Filters = {} as Filters


  // ---------------------------------------------------------------------------
  @Watch('show')
  public onOpened(nv: boolean) {
    if (nv) {
      filterFields.forEach((key: string) => {
        const value = this.$route.query[key]

        if (value === 'true') {
          this.$set(this.filters, key, true)
        }
        else if (value === 'false') {
          this.$set(this.filters, key, false)
        }
        else if (['filter_category_id'].includes(key) && typeof value === 'string') {
          this.$set(this.filters, key, parseInt(value))
        }
        else {
          this.$set(this.filters, key, null)
        }
      })

      this.display = true
    }
  }

  public onChangeValue(key: string, value: undefined | null | string | number | boolean) {
    this.$set(this.filters, key, value)
  }

  public setFilter() {
    this._onCloseEmit(true)
  }

  public resetFilter() {
    this.filters = { ...emptyFilters }
    this._onCloseEmit(true)
  }

  public onClose() {
    this._onCloseEmit()
  }

  @Emit('close')
  private _onCloseEmit(emit = false) {
    this.display = false

    if (emit) {
      return this.filters
    }
  }
}
