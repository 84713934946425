










import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class CheckBox extends Vue {
  @Prop() public value!: undefined | boolean

  @Prop() public label!: string

  /** NB! 3 состояния */
  @Prop({ type: Boolean, default: false }) public indeterminate?: boolean

  public localValue: boolean

  constructor() {
    super()

    this.localValue = !!this.value
  }

  @Watch('value')
  public onValue() {
    this.localValue = !!this.value
  }

  public get isIndeterminate(): boolean {
    if (!this.indeterminate) {
      return false
    }

    return this.value === undefined
  }

  public onInput() {
    let _v
    if (this.indeterminate) {
      /** NB! 3 состояния */
      if (this.isIndeterminate) {
        _v = true
      }

      else if (this.value) {
        _v = false
      }

      else {
        _v = undefined
      }
    }

    else {
      _v = !this.value
    }

    this.$emit('change-value', _v)
  }
}
