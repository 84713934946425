import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse } from 'axios'

interface BrandListState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  documents: any[];
}

const $state: BrandListState = {
  loading: false,
  loaded: false,
  fatalError: false,

  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  documents: [],
}

export const $actions: ActionTree<BrandListState, RootState> = {
  loadData({ state, commit, getters, dispatch }, query) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      const params: any = {}

      if (query.sortField) {
        params.order_by = query.sortField
        params.order_direction = query.sortDirection || 'asc'
      }

      params.page = query.page || 1

      if (query.name) {
        params.search = query.name
      }

      if (query.filter_category_id) {
        params.filter_category_id = query.filter_category_id
      }

      if (query.filter_moderated) {
        params.filter_moderated = query.filter_moderated
      }

      if (query.filter_patent) {
        params.filter_patent = query.filter_patent
      }

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/brands', { params })
        .then((response: AxiosResponse) => {
          const resp_data = response.data

          commit('SET_ITEMS_LIST', resp_data)
          commit('LOADED_SUCCESS')

          resolve(null)

          // const receipts_for_brand_id_request = []
          //
          // for (let i = 0; i < resp_data.brands.length; i++) {
          //   receipts_for_brand_id_request.push(
          //     vm.$http.get('/admins/rest/v1/receipts', { params: { brand_id: resp_data.brands[i].id } }),
          //   )
          // }
          //
          // vm.$http.all(receipts_for_brand_id_request)
          //   .then((responces: AxiosResponse[]) => {
          //
          //     commit('SET_ITEMS_LIST', resp_data)
          //     commit('LOADED_SUCCESS')
          //
          //     resolve(null)
          //   })
          //   .catch((error: AxiosError) => {
          //     commit('SET_ITEMS_LIST', resp_data)
          //     commit('LOADED_SUCCESS')
          //
          //     resolve(null)
          //   })
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
}

export const $mutations: MutationTree<BrandListState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },

  SET_ITEMS_LIST(state, data) {
    state.documents = data.brands

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },
}

export const $getters: GetterTree<BrandListState, RootState> = {}

export const STORE_KEY = '$_brand_list'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
