


















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState, mapActions } from 'vuex'
import { STORE_KEY, store } from './_store'

import CheckBox from '@/components/Controls/CheckBox.vue'
import TitleBar from '@/components/Elements/TitleBar.vue'
import ModalFilter from './ModalFilter.vue'
import { filterFields } from './constants'
import { StrAnyObj } from '@/types/common.types'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading',
      // todo remove >> 'filter',
      'documents',
      'lastMeta',
    ]),
  },
  methods: {
    ...mapActions(STORE_KEY, [
      'exportData',
    ]),
  },
  components: {
    CheckBox,
    TitleBar,
    ModalFilter,
  },
})
export default class BrandList extends Vue {
  public showModal: any = false
  public debounce: any = null

  get titleStack() {
    return [
      'Бренды',
    ]
  }

  public debounceInput(filter: string, value: any) {
    if (value.length >= 3) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.onSearch(filter, value)
      }, 300)
    }

    if (value.length === 0) {
      const newRouteQuery = {};
      const queryKeys = this.$route.query;

      Object.keys(queryKeys)
        .forEach(function(key) {
          if (key !== filter) {
            // @ts-ignore
            newRouteQuery[key] = queryKeys[key];
          }
        });
      this.$router.replace({
        query: newRouteQuery,
      })
    }
  }

  /** todo не используется!? Удалить?
   * public getQueryParam(field: string) {
   * return this.$route.query ? cloneDeep(this.$route.query[field]) : null
   * }
   */

  // ---------------------------------------------------------------------------

  get defaultSort() {
    return [this.$route.query.sortField || 'name', this.$route.query.sortDirection || 'asc']
  }

  public onSort(field: string, direction: string) {
    this.$router.replace({
      query: {
        ...this.$route.query, sortField: field, sortDirection: direction,
      },
    })
  }

  public tableClick(row: any) {
    this.$router.push({ name: 'brand-observe', params: { id: row.id } })
  }

  public onSearch(filter: string, value: any) {
    const query = {
      ...this.$route.query, [filter]: value,
    }

    this.$router.replace({ query })
  }

  public onPageChange(page: string) {
    this.$router.replace({
      query: { ...this.$route.query, page },
    })
  }

  // ---------------------------------------------------------------------------

  @Watch('$route.query')
  public handleParamsChange() {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.$route.query)
  }

  // ---------------------------------------------------------------------------
  /** MODAL >> */
  public onCloseModal(filters: StrAnyObj) {
    const query: StrAnyObj<string> = {}

    Object.entries(this.$route.query)
      .forEach(([key, value]: [string, any]) => {
        if (!filterFields.includes(key)) {
          query[key] = value
        }
      })

    filterFields.forEach((key) => {
      if (['filter_category_id'].includes(key) && !!filters[key]) {
        query[key] = filters[key]
      }

      if ([true, false].includes(filters[key])) {
        query[key] = `${filters[key]}`
      }
    })

    this.$router.replace({ query })
      .catch((err) => {
        // todo remove
        console.log(' *---> ERR', err.name)
      })

    this.showModal = false
  }

  /** MODAL << */

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

}
